





































import Vue from 'vue';
import GradientBlueBanner from '@/components/GradientBlueBanner.vue';
import CustomFooter from '@/components/CustomFooter.vue';

export default Vue.extend({
  name: 'Home',
  components: {
    GradientBlueBanner,
    CustomFooter
  },
  created() {
    document.title = this.$route.meta?.title;
  }
});

