

































import Vue from 'vue';
import GradientBlueBanner from '@/components/GradientBlueBanner.vue';
import ProjectItem from '@/components/ProjectItem.vue';
import CustomFooter from '@/components/CustomFooter.vue';

export default Vue.extend({
  name: 'Projets',
  components: {
    GradientBlueBanner,
    ProjectItem,
    CustomFooter
  },
  created() {
    document.title = this.$route.meta?.title;
  }
});

