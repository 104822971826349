






























































































import Vue from 'vue';

export default Vue.extend({
  name: 'PortfolioItem',
  props: {
    name: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    url: {
      type: String,
      required: true
    },
    imageURL: {
      type: String,
      required: true
    }
  }
});

