






import Vue from 'vue';

export default Vue.extend({
    name: 'DarkmodeSwitcher',
    mounted() {
        const initUserTheme = this.getMediaPreference();
        this.setTheme(initUserTheme || '');
    },
    data() {
        return {
            userTheme: "light-theme",
        };
    },
    methods: {
        toggleTheme() {
            const activeTheme = localStorage.getItem('user-theme');
            const toggledTheme = activeTheme === 'light-theme' ? 'dark-theme' : 'light-theme'
            this.setTheme(toggledTheme);
        },
        setTheme(theme: string) {
            if(theme !== '') {
                localStorage.setItem('user-theme', theme);
                this.userTheme = theme;
                document.documentElement.className = theme;
            }
        },
        getMediaPreference() {
            const hasSystemPreference = window.matchMedia("(prefers-color-scheme: dark)").matches && !localStorage.getItem('user-theme');
            if (hasSystemPreference) {
                return 'dark-theme';
            } else {
                return localStorage.getItem('user-theme') ?? 'light-theme';
            }
        }
    }
});
