













































































import Vue from 'vue';
import GradientBlueBanner from '@/components/GradientBlueBanner.vue';
import SocialMediaItem from '@/components/SocialMediaItem.vue';
import CustomFooter from '@/components/CustomFooter.vue';

export default Vue.extend({
    name: 'Contact',
    components: {
        GradientBlueBanner,
        SocialMediaItem,
        CustomFooter
    },
    created() {
        document.title = this.$route.meta?.title;
    }
});

