











import Vue from 'vue';

export default Vue.extend({
  name: 'ProjectItem',
  props: {
    projectName: {
      type: String,
      required: true
    },
    url: {
      type: String,
      required: true
    },
    backgroundURL: {
      type: String,
      required: true
    }
  }
});
