






import Vue from "vue";

export default Vue.extend({
  name: 'GradientBlueBanner',
  props: {
    page: {
      type: String,
      required: true
    }
  }
})
