import { render, staticRenderFns } from "./SocialMediaItem.vue?vue&type=template&id=230e6a6a&scoped=true&"
import script from "./SocialMediaItem.vue?vue&type=script&lang=ts&"
export * from "./SocialMediaItem.vue?vue&type=script&lang=ts&"
import style0 from "./SocialMediaItem.vue?vue&type=style&index=0&id=230e6a6a&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "230e6a6a",
  null
  
)

export default component.exports