





















































































import Vue from 'vue';
import DarkmodeSwitcher from '@/components/DarkmodeSwitcher.vue';

export default Vue.extend({
  name: 'App',
  components: {
    DarkmodeSwitcher
  }
});
