


















import Vue from 'vue';

export default Vue.extend({
    name: 'SocialMediaItem',
    props: {
        name: {
          type: String,
          required: true
        },
        url: {
          type: String,
          required: true
        },
        icon: {
          type: String,
          required: true
        },
        colorCode: {
          type: String,
          required: true
        }
    },
    computed: {
        badgeBg () {
            return {
                'background-color': this.colorCode,
                height: '100%',
                width: '100%'
            }
        }
    }
});
